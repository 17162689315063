import {booleanAttribute, ChangeDetectionStrategy, Component, Input, numberAttribute} from '@angular/core'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'

type BgIntensityOptions = '0' | '10' | '30' | '50' | 'ghost'

@Component({
  selector: 'cft-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconButtonComponent {
  _bgIntensity: BgIntensityOptions = '10'

  @Input()
  title?: string

  @Input()
  icon!: IconDefinition

  @Input()
  iconColor?: string

  @Input()
  set bgIntensity(value: BgIntensityOptions | undefined) {
    if (!value) return
    this._bgIntensity = value
  }

  //TODO ask marco if the shadow should be a part of the button
  @Input({transform: booleanAttribute})
  hasShadow = false

  /** width and height in px */
  @Input({transform: numberAttribute})
  dimension?: number

  @Input()
  ariaLabel?: string
}
