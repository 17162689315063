import {NgModule} from '@angular/core'
import {IconButtonComponent} from './icon-button/icon-button.component'
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome'
import {CallToActionComponent} from './call-to-action/call-to-action.component'
import {CommonModule} from '@angular/common'
import {PipesModule} from '../../shared/pipes/pipes.module'
import {RouterModule} from '@angular/router'
import {DirectivesModule} from '../../shared/directives/directives.module'
import {ButtonComponent} from './button/button.component'
import {SpinnerComponent} from '../spinner/spinner.component'

@NgModule({
  declarations: [IconButtonComponent, CallToActionComponent, ButtonComponent],
  imports: [CommonModule, RouterModule, FontAwesomeModule, PipesModule, DirectivesModule, SpinnerComponent],
  exports: [IconButtonComponent, CallToActionComponent, ButtonComponent],
})
export class ButtonModule {}
