<button
  class="inline-flex cursor-pointer items-center justify-center rounded-lg px-sm py-xs {{ iconColor }}"
  [class.intensity-0]="_bgIntensity === '0'"
  [class.intensity-10]="_bgIntensity === '10'"
  [class.intensity-30]="_bgIntensity === '30'"
  [class.intensity-50]="_bgIntensity === '50'"
  [class.btn-ghost]="_bgIntensity === 'ghost'"
  [class.shadow-3]="hasShadow"
  [title]="title || ''"
  [style.height.px]="dimension ?? 44"
  [style.width.px]="dimension ?? 44"
  [attr.aria-label]="title || ariaLabel"
>
  <fa-icon [icon]="icon" size="lg" class="{{ iconColor }}"></fa-icon>
</button>
